import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a className="App-link" href="mailto:zachary@zacharyrong.com">
          zachary@zacharyrong.com
        </a>
      </header>
    </div>
  );
}

export default App;
